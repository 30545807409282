.card:not(.hero-project) .card-content a:not(.button-small){
    color: #2d2ce5;
}

.project-content .card-content img, .project-content .card-content .youtube-video {
    border-radius: 32px;
}

/*@media screen and (min-width: 1440px) {*/
/*    .card-content h1, .card-content h2, .card-content h3, .card-content h4, .card-content h5, .card-content h6, .card-content p {*/
/*        width: 70%;*/
/*    }*/
/*}*/

.loading {
    text-align: center;
}