.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-layout-vflex {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.w-layout-hflex {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

body {
  color: #fff;
  background-color: #111;
  font-family: IBM Plex Mono, sans-serif;
  font-size: 16px;
  line-height: 120%;
}

h1 {
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 120%;
}

h2 {
  margin-top: 1.25em;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 120%;
}

h3 {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

h4 {
  margin-top: .5em;
  margin-bottom: .75em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 120%;
}

p {
  margin-bottom: .75rem;
}

a {
  color: #fff;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.color-secondary {
  color: #a0a0a0;
}

.header {
  align-items: flex-start;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.header-left {
  flex: 1;
}

.header-right {
  grid-column-gap: 1rem;
  align-items: center;
}

.header-h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2em;
}

.header-p {
  margin-bottom: 0;
}

.button {
  text-align: center;
  background-color: #2d2ce5;
  border: 1px solid #2d2ce5;
  border-radius: .75rem;
  align-self: flex-start;
  font-size: 1em;
  transition: transform .1s ease-in;
}

.button:hover {
  background-color: rgba(45, 44, 229, 0);
  transform: scale(1.05);
}

.button.button-small {
  color: #2d2ce5;
  background-color: rgba(255, 255, 255, 0);
  align-self: flex-start;
  font-size: .875rem;
}

.button.button-small:hover {
  color: #fff;
  background-color: #2d2ce5;
}

.button.button-projects {
  align-self: stretch;
}

.header-a {
  text-decoration: none;
}

.header-a:hover {
  text-decoration: underline;
}

.card {
  width: 100%;
  color: #111;
  background-color: #f7f7f7;
  background-image: linear-gradient(143deg, rgba(45, 44, 229, .2), rgba(17, 17, 17, 0));
  border-radius: 2rem;
  order: 0;
  position: relative;
}

.card.back-project {
  z-index: 0;
  opacity: .4;
  position: absolute;
  top: 0;
  left: -33%;
  transform: scale(.5);
}

.card.back-project.right {
  left: 33%;
}

.card.hero-project {
  z-index: 1;
  transition: none;
}

.card.hero-project:hover {
  transform: none;
}

.card-content {
  width: 100%;
  grid-row-gap: .25rem;
  text-align: left;
  flex-direction: column;
  padding: .5rem 2rem 2rem;
  display: flex;
  position: relative;
}

.card-content.full {
  padding-left: 2rem;
  padding-right: 2rem;
}

.card-content.card-footer0 {
  padding-bottom: 0;
}

.p-small {
  font-size: .875em;
}

.footer {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cards-static {
  grid-column-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 4rem;
  display: grid;
}

.column {
  padding-left: 0;
  padding-right: 0;
}

.columns {
  margin-left: 0;
  margin-right: 0;
}

.services-list {
  margin-top: .5rem;
  margin-bottom: 1rem;
  padding-left: 0;
}

.services-list-li {
  margin-bottom: .5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.cards-static-col {
  grid-row-gap: 2rem;
}

.member-img {
  width: 4rem;
  height: 4rem;
}

.member {
  grid-row-gap: .5rem;
  text-align: center;
  flex: 1;
  align-self: flex-start;
  align-items: center;
}

.members {
  grid-column-gap: 1rem;
  align-items: flex-start;
  margin-bottom: .5rem;
}

.projects {
  grid-row-gap: 2rem;
  flex-direction: column;
  grid-template: "."
                 "Area"
                 / 1fr;
  grid-auto-columns: 1fr;
  display: flex;
  position: relative;
}

.card-content-meta {
  justify-content: space-between;
}

.card-content-link {
  z-index: 2;
  color: #2d2ce5;
  cursor: pointer;
  padding-top: .25em;
  padding-bottom: .25em;
  font-size: 1em;
  font-weight: 700;
  display: inline-block;
  position: relative;
}

.card-content-services {
  margin-bottom: 0;
  padding-top: .25em;
  padding-bottom: .25em;
}

.card-image {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.project-left {
  z-index: 0;
  height: 100%;
  position: absolute;
  left: -100%;
}

.card-fake-link {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.logo-span {
  font-style: italic;
}

.hidden {
  display: none;
}

.active {
  text-decoration: none;
}

.stack-img {
  height: 4rem;
  padding: .5rem;
}

.stack {
  align-items: center;
}

.stacks {
  grid-column-gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (min-width: 1440px) {
  .header-h1 {
    font-size: 2em;
  }

  .container {
    max-width: 1280px;
  }

  .card-content-p-short {
    width: 70%;
  }
}

@media screen and (min-width: 1920px) {
  .header-h1 {
    font-size: 2em;
  }

  .button {
    font-size: 1em;
  }

  .button.button-small, .p-small {
    font-size: .875em;
  }

  .container {
    max-width: 1440px;
  }

  .body {
    font-size: 24px;
  }
}

@media screen and (max-width: 991px) {
  .header {
    grid-row-gap: 1rem;
    flex-direction: column;
  }

  .button.button-small {
    align-self: center;
  }

  .header-link {
    cursor: pointer;
  }

  .card.back-project {
    display: none;
  }

  .card.hero-project {
    transition: none;
  }

  .card.hero-project:hover {
    transform: none;
  }

  .footer {
    text-align: center;
  }

  .member {
    align-self: center;
  }

  .members {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .header {
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .cards-static {
    grid-row-gap: 2rem;
    flex-direction: column;
    display: flex;
  }

  .members {
    flex-direction: row;
  }

  .card-content-meta {
    flex-direction: column;
  }
}

@media screen and (max-width: 479px) {
  .header-right {
    width: 100%;
    grid-row-gap: 1rem;
    flex-direction: column-reverse;
  }

  .header-h1 {
    font-size: 1.5rem;
  }

  .header-p {
    font-size: .875rem;
  }

  .button {
    align-self: stretch;
  }

  .card-content {
    width: 100%;
  }

  .dot {
    display: none;
  }

  .members {
    flex-direction: column;
  }

  .card-content-meta {
    grid-row-gap: .5rem;
  }
}

#w-node-afc9224e-f0c8-9951-cb1f-2ffc6413d436-9181a858, #w-node-afc9224e-f0c8-9951-cb1f-2ffc6413d460-9181a858, #w-node-_9c6342ef-2864-6325-7c25-8e9b1036149c-682344a5, #w-node-_9c6342ef-2864-6325-7c25-8e9b103614c6-682344a5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}


